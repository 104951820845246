import React from 'react';
import tw from 'twin.macro';
import styled from 'styled-components';
import Col from 'components/Col';
import Text from 'components/Text';
import Number from 'components/Number';
import Row from 'components/Row';
import { ActionButton } from 'components/Button';
import moment from 'moment';
import { currencyToText } from 'utils/money';
import { Box } from 'grommet';

const CartWrapper = styled.div(() => [tw`bg-transparent p-2 w-full`]);
const CartInnerWrapper = styled.div(() => [tw`shadow-lg rounded-lg p-4 bg-white`]);
const CartItemsContainer = styled(Row)(() => [
  tw`grid grid-cols-3 py-2 justify-center align-middle`
]);
const Divider = tw.div`divide-y-2 divide-solid  divide-gray-100 py-2`;

interface CartProps {
  event: namespace.Event;
  items?: namespace.CartEntry[];
  onComplete;
}

interface CartItemProps {
  name: string;
  quantity?: number;
  price?: number;
  skipCalc?: boolean;
}

function CartItem({ name, quantity, price, currency, skipCalc, days, ...props }: CartItemProps) {
  return (
    <CartItemsContainer>
      <Text label={name} {...props} bold />
      <Row centered>
        <Text label={`${quantity ? `X ${quantity}` : ''}`} {...props} />
      </Row>
      <Col>
        <Number
          currency={currency}
          amount={skipCalc ? price : price * (quantity || 1)}
          {...props}
        />
      </Col>
    </CartItemsContainer>
  );
}
CartItem.defaultProps = {
  name: '',
  price: 0
};

function Cart({
  event,
  children,
  day,
  days,
  order,
  title,
  items,
  tax,
  onComplete,
  onBack,
  backEnabled,
  backText,
  submitText,
  hideFooter,
  location,
  ...props
}: CartProps) {
  const total = Object.values(items).reduce(
    (t, { price, quantity }) => {
      return { price: price * quantity + t.price, quantity: t.quantity + quantity };
    },
    { price: 0, quantity: 0 }
  );
  const onContinue = () => {
    onComplete();
  };
  const taxed = (tax / 100) * total.price;
  let lastGroup = '';
  const itemComponents = [];
  items.forEach((item) => {
    itemComponents.push(
      <>
        {lastGroup !== item.group_id && (
          <Col spacing="sm">
            <Text label={item.group_name} bolder small />
            <Text label={item.group_description} bold small />
          </Col>
        )}
        <CartItem key={item.name} days={days} {...item} small />
      </>
    );
    lastGroup = item.group_id;
  });
  return (
    <CartWrapper {...props}>
      <CartInnerWrapper>
        <Box pad={{ horizontal: 'small' }}>
          <Box direction="row" gap="small" justify="between" align="baseline">
            <Text label={`${title} `} large bold />
            {order && <Text label={`#${order?.id}`} />}
          </Box>
          {event && (
            <Text
              label={`${new Date(day).toLocaleDateString('en-us', {
                weekday: 'long',
                year: 'numeric',
                month: 'short',
                day: 'numeric'
              })} `}
              small
            />
          )}
          <Box>
            <Text label={location?.venue?.name} small />
          </Box>
          {itemComponents}
          <Divider />
          <CartItem key="total" name="Total" price={total.price + taxed} currency="KES" />
          {!hideFooter && (
            <Row spacing="xs" spread>
              <ActionButton
                primary
                bold
                onClick={() => onContinue()}
                disabled={total.quantity === 0 || (event?.venues?.length > 0 && !location)}
              >
                {submitText || 'Continue'}
              </ActionButton>
            </Row>
          )}
        </Box>
      </CartInnerWrapper>
    </CartWrapper>
  );
}

Cart.defaultProps = {
  title: 'Your Order',
  items: [],
  tax: 0.0
  //   onComplete: () => {
  //     console.error('missing prop Cart:onComplete');
  //   }
};
export default Cart;
